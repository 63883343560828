import React from 'react';
import {isExperienceEditorActive, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import {
    ProductCTAContainer,
    ContentWrapper,
    HeadingWrapper,
    CopyWrapper,
    ButtonsWrapper,
    ImageContainerWrapper, ProductCTAWrapper
} from './ProductCTA.styles.js';
import Button from 'components/shared/Button';
import { useDeviceResize } from 'hooks/index.js';
import PropTypes from "prop-types";
import {checkBadges, languageFilter} from "../../helpers/badgeHelpers";
import ProductDescription from "components/ProductDescription";

const ProductCTA = ({ title, copy, image, cta, pageType, activeImgSrc,  productCanImageField, consumerUnitFrontStraightOnTransparentPNGFR, consumerUnitFrontStraightOnTransparentPNG, ...props }) => {
    const experienceEditor = isExperienceEditorActive();
    const {fields = {}, params = {}, sitecoreContext} = props;
    const { ctaLocation = {}} = fields;
    const {style = ''} = params;
    const device = useDeviceResize();
    const {site} = sitecoreContext;
    const canProductImage =
        productCanImageField === 'consumerUnitFrontStraightOnTransparentPNGFR'
            ? consumerUnitFrontStraightOnTransparentPNGFR
            : consumerUnitFrontStraightOnTransparentPNG;

    //console.log('product cta activeImgSrc', activeImgSrc);
    //console.log('product cta canProductImage', canProductImage);

    if (experienceEditor || sitecoreContext?.pageEditing) {
        return (
            <ProductCTAWrapper site={site}>
                <ProductCTAContainer>
                    <ContentWrapper site={site}>
                        {title && <HeadingWrapper>{typeof title === 'string' ? title : title.value}</HeadingWrapper>}
                        {copy && (typeof copy === 'string' ? copy.length > 5 : copy.value.length > 5) && (
                            <CopyWrapper>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: typeof copy === 'string' ? copy : copy.value,
                                    }}
                                />
                            </CopyWrapper>
                        )}
                        <ButtonsWrapper site={site}>
                            {cta && cta.value && (
                                <Button
                                    text={cta.value.text}
                                    href={cta.value.href}
                                    variant="blue"
                                    size={device === 'desktop' ? 'large' : 'small'}
                                />
                            )}
                            {/*{ctaLocation && ctaLocation.value && (*/}
                            {/*    <Button*/}
                            {/*        text={ctaLocation.value.text}*/}
                            {/*        href={ctaLocation.value.href}*/}
                            {/*        variant="blue"*/}
                            {/*        size={device === 'desktop' ? 'large' : 'small'}*/}
                            {/*    />*/}
                            {/*)}*/}
                        </ButtonsWrapper>
                    </ContentWrapper>
                    {canProductImage && (
                        <ImageContainerWrapper>
                            {canProductImage.map(
                                (image, index) =>
                                    index === 0 && (
                                        <img
                                            key={`ProductDetailHero-${image.key}-${index}`}
                                            src={image.url}
                                            alt={image.description}
                                        />
                                    )
                            )}
                            {activeImgSrc && <img className="badge" src={activeImgSrc} alt={'New Look'}/>}

                        </ImageContainerWrapper>
                    )}
                </ProductCTAContainer>
            </ProductCTAWrapper>
        );
    }

    return (
        <ProductCTAWrapper site={site}>
            <ProductCTAContainer>
                <ContentWrapper site={site}>
                    {title && <HeadingWrapper>{typeof title === 'string' ? title : title.value}</HeadingWrapper>}

                    <ButtonsWrapper site={site}>
                        {/*{cta && cta.value && (*/}
                        {/*    <Button*/}
                        {/*        text={cta.value.text}*/}
                        {/*        href={cta.value.href}*/}
                        {/*        variant="yellowBlue"*/}
                        {/*        size={device === 'desktop' ? 'large' : 'small'}*/}
                        {/*    />*/}
                        {/*)}*/}
                        {ctaLocation && ctaLocation.value && (
                            <Button
                                text={ctaLocation.value.text}
                                href={ctaLocation.value.href}
                                variant="blue"
                                size={device === 'desktop' ? 'large' : 'small'}
                            />
                        )}
                        {pageType === "product" && (
                            <Button
                                text="Contact a Rep"
                                href="/contact"
                                variant="yellowBlue"
                                size={device === 'desktop' ? 'large' : 'small'}
                            />
                        )}


                    </ButtonsWrapper>
                </ContentWrapper>
                {canProductImage && (
                    <ImageContainerWrapper>
                        {canProductImage.map(
                            (image, index) =>
                                index === 0 && (
                                    <img
                                        key={`ProductDetailHero-${image.key}-${index}`}
                                        src={image.url}
                                        alt={image.description}
                                    />
                                )
                        )}
                        {activeImgSrc && <img className="badge" src={activeImgSrc} alt={'New Look'}/>}

                    </ImageContainerWrapper>
                )}
            </ProductCTAContainer>
        </ProductCTAWrapper>
    );
};

ProductCTA.propTypes = {
    fields: PropTypes.shape({
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        copy: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        image: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        cta: PropTypes.shape({
            href: PropTypes.string,
            text: PropTypes.string,
            onClick: PropTypes.string,
        }),
        ctaLocation: PropTypes.shape({
            href: PropTypes.string,
            text: PropTypes.string,
            onClick: PropTypes.string,
        }),
    }),
    params: PropTypes.shape({
        style: PropTypes.oneOf(['red', 'tan', 'yellow', 'blue','brown', 'transparent']),
    }),
};

export default withSitecoreContext()(ProductCTA);