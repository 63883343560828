import React, { useState } from 'react';
import { DesktopNavComponent, AboutUsSubNav, ProductsSubNav } from './DesktopNav.styles.js';
import { FavouritesButton, SearchButton } from '../../MainNav.styles';
import Hamburger from '../Hamburger';
import NavLink from '../NavLink';
import Link from 'components/shared/Link';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';
import Icon from 'components/shared/Icon';
import MainNavAboutUsLink from '../MainNavAboutUsLink';
import MainNavProductLink from '../MainNavProductLink';
import MainNavRibbon from '../MainNavRibbon';
import { underlineText } from 'helpers/stringHelpers';

import Overlay from 'components/shared/Overlay';
import Button from "../../../shared/Button";

export const DesktopNav = ({
                               headerContext = {},
                               fields,
                               sitecoreContext: { globalContent, route, site },
                               search,
                               setSearch,
                               className,
                               ...props
                           }) => {


    if (headerContext) {
        if (Array.isArray(headerContext.globalContent)) {
            globalContent = [...globalContent, ...headerContext.globalContent];
        } else if (typeof headerContext.globalContent === 'object' && headerContext.globalContent !== null) {
            globalContent = {...globalContent, ...headerContext.globalContent};
        } else {
            const globalContent = [headerContext.globalContent];
        }
    }
    const links = globalContent?.navigation;
    const utilityLinks = globalContent?.utilityLinks;
    const logo = globalContent?.logo;
    const navigation = globalContent?.navigation;
    const homePageLink = globalContent?.homepageLink;
    const languageSelector = globalContent?.languageSelectorItems;
    const heartLink = globalContent?.heartLink;
    const heartIcon = globalContent?.heartIcon;
    const ProductsText =
        Array.isArray(links) &&
        links.find(
            ({ type, cards }) =>
                type === 'megamenu-cards' && cards && cards.filter(({ type }) => type === 'product').length > 0
        )?.text;
    const [subNav, setSubNav] = useState(false);

    const supportingLinks = navigation?.reduce((acc, el) => {
        if (el?.supportingLinks?.length) {
            return el.supportingLinks;
        }
        return [...acc];
    }, []);

    const handleLanguageSelector = (newLangCode) => {
        if (!newLangCode) return;

        if (!isServer()) {
            let splitPath = window.location.pathname.split('/');
            splitPath[1] = newLangCode;
            return (window.location.href = splitPath.join('/'));
        }
    };

    //console.log('DesktopNav', {globalContent, route});
    return (
        <>
            <DesktopNavComponent search={search} className={`hide-on-print nav-${className}`}>
                <Hamburger aria-label="Menu toggle" className="hamburger" />
                <nav aria-label="Main Navigation" role="navigation" className="hide-on-print">
                    {Array.isArray(links) &&
                        links.map(({ path, title, type, cards }, index) => {
                            if (type && type === 'megamenu-cards') {
                                if (cards && cards.filter(({ type }) => type === 'product').length > 0) {
                                    return (
                                        <NavLink
                                            key={`${index}-${title}`}
                                            text={title}
                                            subNav
                                            active={subNav === 'products' ? 'true' : 'false'}
                                            path={path}
                                            aria-haspopup={type === 'megamenu-cards' ? 'true' : undefined}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (subNav === 'about-us') {
                                                    setSubNav('products');
                                                    return;
                                                }
                                                if (subNav === 'products') {
                                                    setSubNav(false);
                                                    return;
                                                }
                                                setSubNav('products');
                                            }}
                                        />
                                    );
                                }
                                if (cards.filter(({ type }) => type === 'page').length > 0) {
                                    return (
                                        <NavLink
                                            key={`${index}-${title}`}
                                            text={title}
                                            subNav
                                            active={subNav === 'about-us' ? 'true' : 'false'}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (subNav === 'products') {
                                                    setSubNav('about-us');
                                                    return;
                                                }
                                                if (subNav === 'about-us') {
                                                    setSubNav(false);
                                                    return;
                                                }
                                                setSubNav('about-us');
                                            }}
                                        />
                                    );
                                }
                            }
                            return <NavLink key={`${index}-${title}`} text={title} path={path} />;
                        })}
                </nav>
                <div className="logo hide-on-print">
                    <Link href={homePageLink}>
                        {/* eslint-disable-next-line jsx-a11y/alt-text */}
                        <img src={logo?.src} alt={logo?.alt} />
                    </Link>
                </div>

                <div aria-label="Utility Navigation" role="menu" className="nav-end hide-on-print" >
                    {Array.isArray(languageSelector) && languageSelector.length
                        ? languageSelector.map(({ text, code }, index) => {
                            return (
                                <NavLink
                                    className="nav-end-link"
                                    role="menuitem"
                                    key={`languageSelector-${index}-${text}`}
                                    text={text}
                                    onClick={() => handleLanguageSelector(code)}
                                />
                            );
                        })
                        : null}
                    {Array.isArray(utilityLinks) &&
                        utilityLinks.map(({ title, path, href }, index) => {
                            return (
                                <NavLink
                                    className="nav-end-link"
                                    role="menuitem"
                                    key={`${index}-${title}`}
                                    text={title}
                                    path={path}
                                    href={href}
                                />
                            );
                        })}

                    {site?.name !== "bushs-beans-foodservices" ? (
                        <>
                            <FavouritesButton aria-label="Add to Favorites" role="menuitem" classname="fave-button">
                                <Link path={heartLink?.path}>
                                    <img src={heartIcon?.src} alt={heartIcon?.alt} title="My Favorites" />
                                </Link>
                            </FavouritesButton>
                            <SearchButton
                                id="search-button"
                                className="search-button"
                                disabled={route?.name === 'search'}
                                aria-label="Open Search"
                                title="Open Search"
                                onClick={() => {
                                    setSearch((prevState) => !prevState);
                                }}
                            >
                                <Icon className="search" icon={search ? 'cross' : 'search'} size="lg" />
                            </SearchButton>
                        </>
                    ) : (
                        <Button id="MainNavBtn" target="_blank" text="Contact a Rep" size="small" href="/contact" variant="yellowBlue" />
                    )}

                </div>

                <AboutUsSubNav active={subNav === 'about-us'}>
                    <div className="card-list">
                        {Array.isArray(links) &&
                            links
                                .find(
                                    ({ type, cards }) =>
                                        type === 'megamenu-cards' &&
                                        cards &&
                                        cards.filter(({ type }) => type === 'page').length > 0
                                )
                                ?.cards.map(({ title, image, alt, link }, index) => {
                                return (
                                    image?.src && (
                                        <MainNavAboutUsLink
                                            key={`${index}-${title}`}
                                            text={title}
                                            src={image.src}
                                            alt={`view ${alt} page`}
                                            path={link?.path}
                                        />
                                    )
                                );
                            })}
                    </div>
                </AboutUsSubNav>
                <ProductsSubNav active={subNav === 'products'}>
                    <div className="container">
            <span
                className="h1"
                dangerouslySetInnerHTML={{
                    __html: ProductsText
                        ? underlineText(ProductsText, {
                            openTag: '<u>',
                            closeTag: '</u>',
                        })
                        : '',
                }}
            />
                        <div className="product-list">
                            {Array.isArray(links) &&
                                links
                                    .find(
                                        ({ type, cards }) =>
                                            type === 'megamenu-cards' &&
                                            cards &&
                                            cards.filter(({ type }) => type === 'product').length > 0
                                    )
                                    ?.cards.map(({ title, image, link }, index) => {
                                    return (
                                        image?.src && (
                                            <MainNavProductLink
                                                key={`${index}-${title}`}
                                                productName={title}
                                                productImage={image.src}
                                                buttonText={link?.title}
                                                path={link?.path}
                                            />
                                        )
                                    );
                                })}
                        </div>
                    </div>
                    {supportingLinks?.length && <MainNavRibbon supportingLinks={supportingLinks} />}
                </ProductsSubNav>
            </DesktopNavComponent>
            {(subNav === 'products' || subNav === 'about-us') && (
                <Overlay id="overlay" onClick={() => setSubNav('')} />
            )}
        </>
    );
};

export default withSitecoreContext()(DesktopNav);