/* eslint-disable react/prop-types */
import React, {useRef, useState, useEffect} from 'react';
// import { NavLink } from 'react-router-dom';
// import { withTranslation } from 'react-i18next';
import {Placeholder, VisitorIdentification, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import GlobalStyle from 'style/global/GlobalStyles';
import MainNav from 'components/MainNav';
import Footer from 'components/Footer';
import AlertRibbon from 'components/AlertRibbon';
import SearchBox from 'components/SearchBox';
import UtilityNav from "components/UtilityNav/UtilityNav.jsx";
import styled from 'styled-components';
import {useOnClickOutside} from 'hooks';


/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

/* Leaving the navigation boilerplate as an example of a translation implementation */
// This is boilerplate navigation for sample purposes. Most apps should throw this away and use their own navigation implementation.
// Most apps may also wish to use GraphQL for their navigation construction; this sample does not simply to support disconnected mode.
// let Navigation = ({ t, i18n }) => (
//   <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom">
//     <h5 className="my-0 mr-md-auto font-weight-normal">
//       <NavLink to="/" className="text-dark">
//         <img src={logo} alt="Sitecore" />
//       </NavLink>
//     </h5>
//     <nav className="my-2 my-md-0 mr-md-3">
//       <a className="p-2 text-dark" href="https://jss.sitecore.net" target="_blank" rel="noopener noreferrer">
//         {t('Documentation')}
//       </a>
//       <NavLink to="/styleguide" className="p-2 text-dark">
//         {t('Styleguide')}
//       </NavLink>
//       <NavLink to="/graphql" className="p-2 text-dark">
//         {t('GraphQL')}
//       </NavLink>
//     </nav>
//   </div>
// );

// inject dictionary props (`t`) into navigation so we can translate it
// NOTE: using this is needed instead of using i18next directly to keep
// the component state updated when i18n state (e.g. current language) changes
// Navigation = withTranslation()(Navigation);

const SearchBoxInner = styled.div`
    position: absolute;
    right: 0;
    width: 100%;
    height: 180px;
    top: ${({search}) => (search ? '0' : '-180px')};
    background: ${({theme}) => theme.colours.tan};
    transition: top 0.3s;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    & .search-box {
        max-width: 90%;
        width: 100%;
        margin: 0 auto;

        ${({theme}) => theme.breakPoints.minBp('desktop')} {
            max-width: 60%;
        }
    }
`;

export const SearchBoxContainer = styled.div`
    position: relative;
    width: 100%;
    z-index: ${({theme}) => theme.zindex.search.main};
`;

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({theme}) => theme.zindex.search.overlay};
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    visibility: ${({search}) => (search ? 'visible' : 'hidden')};
    cursor: pointer;
    opacity: ${({search}) => (search ? '1' : '0')};
    backdrop-filter: blur(1px);
    transition: opacity 0.2s;
`;

const Layout = ({route, sitecoreContext}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [search, setSearch] = useState(false);
    const [noSearchValue, setNoSearchValue] = useState(false);
    const searchRef = useRef(null);
    const { site } = sitecoreContext;
    useOnClickOutside(
        searchRef,
        () => {
            setSearch(false);
        },
        'search-button'
    );

    const {globalContent = {}, recipeInfo = {}, language = 'en'} = sitecoreContext;
    const {metadata = {}, siteName = ''} = globalContent;
    const {
        pageTitle = '',
        metadataTitle = '',
        metadataDescription = '',
        metadataImage = '',
        metadataUrl = '',
        canonicalUrl = '',
        siteDomain = '',
        hreflangLinks = [],
    } = metadata;

    const hideBranding = metadataDescription === 'hideBranding';
    useEffect(() => {
        if (metadataDescription === 'devMode') {
            const urlParams = new URLSearchParams(window.location.search);
            const preview = urlParams.get('preview') === 'true';
            if (!preview) {
                window.location.href = 'https://www.bushbeans.com/en_US/not-found';
            }
        }
    }, [metadataDescription]);

    return (
        <React.Fragment>
            {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
            <Helmet>
                <title>{`${pageTitle || route?.fields?.pageTitle?.value} | ${siteName || 'BUSH’S® Beans'}`}</title>
                {metadataTitle && <meta name="title" content={metadataTitle}/>}
                {metadataDescription && <meta name="description" content={metadataDescription}/>}
                <link rel="canonical" href={canonicalUrl || `${siteDomain}${metadataUrl}`}/>
                {language && <meta httpEquiv="content-language" content={language}></meta>}
                {Array.isArray(hreflangLinks) &&
                    hreflangLinks.length > 0 &&
                    hreflangLinks.map((lang, index) => (
                        <link
                            key={`hrefLang-${index}`}
                            rel="alternative"
                            hrefLang={lang?.hreflang.substring(0, 2)}
                            href={`${siteDomain}${lang?.href}`}
                        />
                    ))}
                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content={siteName || 'BUSH’S® Beans'}/>
                {metadataUrl && <meta property="og:url" content={`${siteDomain}${metadataUrl}`}/>}
                {metadataTitle && <meta property="og:title" content={metadataTitle}/>}
                {metadataDescription && !hideBranding &&
                    <meta property="og:description" content={metadataDescription}/>}
                {hideBranding && <meta name="robots" content="noindex, nofollow"/>}
                {metadataImage && <meta property="og:image" content={`${siteDomain}${metadataImage}`}/>}
                {/* Twitter */}
                <meta property="twitter:card" content={metadataImage ? 'summary_large_image' : 'summary'}/>
                {metadataUrl && <meta property="twitter:url" content={`${siteDomain}${metadataUrl}`}/>}
                {metadataTitle && <meta property="twitter:title" content={metadataTitle}/>}
                {metadataDescription && <meta property="twitter:description" content={metadataDescription}/>}
                {metadataImage && <meta property="twitter:image" content={`${siteDomain}${metadataImage}`}/>}
                <meta name="google-site-verification" content="R5SVmDMYdTSwBSEA4ANKPsUH5BHdqVQeSB7zWcYLYZo"/>
                {/* recipe schema */}
                {recipeInfo?.recipeSchemaJson && (
                    <script type="application/ld+json">{sitecoreContext.recipeInfo.recipeSchemaJson}</script>
                )}
                {/* basketful */}
                <script src="https://bonsai.basketful.co/basketful.js" async/>
                {/* Adobe Analytics */}
                <script src={process.env.REACT_APP_ADOBE_ANALYTICS} async/>
                {/* Kenshoo Tag */}
                <script>
                    {`!function(a,b,c,d,e,f,g){a.ktag||(e=function(){e.sendEvent?e.sendEvent(arguments):e.ktq.push(arguments)},e.ktq=[], a.ktag=e,f=b.getElementsByTagName(d)[0],g=b.createElement(d),g.async=!0,g.src=c,f.parentNode.appendChild(g)) }(window,document,"https://resources.xg4ken.com/js/v2/ktag.js?tid=KT-N35ED-3EB","script"); ktag('setup', 'KT-N35ED-3EB', '<USER_ID>');`}
                </script>
                <noscript>
                    {`<img
            src="https://events.xg4ken.com/pixel/v2?tid=KT-N35ED-3EB&noscript=1"
            width="1"
            height="1"
            style="display:none"
          />`}
                </noscript>
                {/* Microsoft/Bing */}
                {/*
        <script>{`(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"4075953"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`}</script>
        */}
                {site?.name !== "bushs-beans-foodservices" && (
                    <script type="text/javascript">{`
                if(typeof window !=='undefined'){
                    const gid = (new URL(window.location.href)).hostname.split('bushbeans').pop() === '.ca' ? 'GTM-PNZXTQLJ' : 'GTM-5NSMC9T';
                    (function (w, d, s, l, i) {
                        w[l] = w[l] || [];
                        w[l].push({
                            'gtm.start': new Date().getTime(),
                            event: 'gtm.js'
                        });
                        var f = d.getElementsByTagName(s)[0],
                            j = d.createElement(s), 
                            dl = l != 'dataLayer' ? '&l=' + l : ''; 
                        j.async = true; 
                        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                        f.parentNode.insertBefore(j, f);
                    })(window, document, 'script', 'dataLayer', gid);
                }
            `}</script>
                )}
                {/* Global site tag (gtag.js) - Google Ads: 976875304 */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-976875304"></script>
                <script>
                    {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-976875304');
            gtag('event', 'conversion', {'send_to': 'AW-976875304/lN_TCL3xo_YBEKje59ED'});
          `}
                </script>
                {/* Yahoo ad platform retargeting/tracking */}
                <script type="application/javascript">
                    {`(function(w,d,t,r,u){w[u]=w[u]||[];w[u].push({'projectId':'10000','properties':{'pixelId':'10193888','he': '<email_address>','auid': '<sha256_hashed_user_id>'}});var s=d.createElement(t);s.src=r;s.async=true;s.onload=s.onreadystatechange=function(){var y,rs=this.readyState,c=w[u];if(rs&&rs!="complete"&&rs!="loaded")
            {return}
            try{y=YAHOO.ywa.I13N.fireBeacon;w[u]=[];w[u].push=function(p)
            {y([p])}
            ;y(c)}catch(e){}};var scr=d.getElementsByTagName(t)[0],par=scr.parentNode;par.insertBefore(s,scr)})(window,document,"script","https://s.yimg.com/wi/ytc.js","dotq");
          `}
                </script>
                {/* New Pixel Code */}
                <script type="text/javascript">
                    {`function zync_call() {
        var z = document.createElement("script");
        var zmpID="bushs-best";
        var cache_buster=Date.now();
        var page_referrer=encodeURIComponent(document.referrer);

        var z_src = "https://live.rezync.com/sync?c=16b6410431b6374e780104abb0443ca8&p=277a2abc64d4cd28ad5fe827f832b5a7&k=bushs-best-pixel-8949&zmpID="+zmpID+"&cache_buster="+cache_buster+"&page_referrer="+page_referrer;
        z.setAttribute("src", z_src);
        document.body.appendChild(z);
    }

    if (['complete', 'interactive'].indexOf(document.readyState) >= 0) {
        zync_call();
    } else {
        window.addEventListener("DOMContentLoaded", function(){
            zync_call();
        });
    }`}
                </script>
                <script type='text/javascript'>
                    {`!function(c, l, e, v, n, t, s)
        {
            if (c.clq) return; n = c.clq = function() { n.callMethod? n.callMethod.apply(n, arguments) : n.queue.push(arguments) };
            if (!c._clq) c._clq = n; n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = [];
            t = l.createElement(e); t.async = !0; t.src = v; s = l.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
        }
        (window, document, 'script', 'https://cdn.clinch.co/a_js/client_pixels/clq/script.min.js');
        clq('init', 'wjav1IL', {'cid':'wobnWYL', 'product':'ecommerce'});
        clq('track', 'PageView');`}
                </script>
                {/* End Pixel Code */}
            </Helmet>
            <GlobalStyle/>
            {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
            <VisitorIdentification/>
            <AlertRibbon/>
            {site?.name === "bushs-beans-foodservices" && <UtilityNav search={search} setSearch={setSearch} />}
            {!hideBranding && <MainNav search={search} setSearch={setSearch}/>}
            {!hideBranding &&
                <section aria-label="Search Container">
                    <SearchBoxContainer search={search} ref={searchRef} className="hide-on-print">
                        <SearchBoxInner search={search}>
                            <SearchBox
                                searchValue={searchTerm}
                                showError={noSearchValue}
                                onChange={(event) => {
                                    setSearchTerm(event);
                                    if (noSearchValue) setNoSearchValue(false);
                                }}
                                onSubmit={(event, inputValue) => {
                                    if (searchTerm.length > 0) {
                                        window.location.href = `${globalContent.homepageLink}search/?type=all&searchTerm=${searchTerm}`;
                                        setSearch(false);
                                    } else {
                                        setNoSearchValue(true);
                                    }
                                }}
                                className="search-box"
                            />
                        </SearchBoxInner>
                    </SearchBoxContainer>
                    <Overlay search={search}/>
                </section>}
            {/* root placeholder for the app, which we add components to using route data */}
            <main className="container">
                <Placeholder name="jss-main" rendering={route}/>
                <div id="modal-container"/>
            </main>
            {!hideBranding && <Footer/>}
        </React.Fragment>
    );
};

export default withSitecoreContext()(Layout);