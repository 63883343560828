import styled from "styled-components";
const getVariantColours = ({ variant, theme } = {}) => {
    const variants = {
        red: {
            background: theme.colours.red,
            text: '#fff',
        },
        yellow: {
            background: theme.colours.yellow,
            text: theme.colours.brown,
        },
        blue: {
            background: theme.colours.blue,
            text: theme.colours.yellow,
        },
        tan: {
            background: theme.colours.tan,
            text: theme.colours.brown,
        },
        brown: {
            background: theme.colours.brown,
            text: theme.colours.yellow,
        },
    };

    if (variant in variants) return variants[variant];

    return variants.tan;
};

const checkAlignment = ({ alignment = 'left' }) => {
    const alignments = {
        left: 'left',
        center: 'center',
        right: 'right',
    };

    if (alignment in alignments) return alignments[alignment];

    return alignments.left;
};


export const CenteredVideoOuterWrapper = styled.section`
    position: relative;
	background-color: ${({ theme, backgroundColor }) =>
		getVariantColours({ variant: backgroundColor, theme }).background};
	padding: ${({ theme }) => theme.spacing({ top: 0, bottom: 0, left: 20, right: 20 })};
	${({ backgroundImage }) =>
		backgroundImage &&
		`
        background-image: url(${backgroundImage});
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    `}
    margin: 80px auto;
	@media (min-width: 900px) {
		margin: 160px auto;
	}
    .sdg-graphic{
		position: absolute;
		top: 80px;
		left: 13%;
		width: 123px;
		height: auto;
		transform: rotate(13deg);
		@media (max-width: 1788px){
            left: 6%;
        }
		@media (max-width: 1588px){
			left: 3%;
		}
		@media (max-width: 1380px){
			display: none;
		}
    }
    .secret-family-recipe-can{
        position: absolute;
        width: 103px;
		bottom: 23%;
        right: 10%;
		transform: rotate(-25deg);
        @media (max-width: 1788px){
			right: 10%;
        }
		@media (max-width: 1588px){
			right: 3%;
		}
		@media (max-width: 1380px){
			display: none;
		}
    }
    .beanTV{
		width: 180px;
		position: absolute;
		top: -110px;
		right: 8%;
		transform: rotate(-25deg);
		@media (max-width: 1588px){
			right: 3%;
		}
		@media (max-width: 1380px){
			display: none;
		}
    }
    .NineteenNfourSticker{
		position: absolute;
		width: 130px;
		right: 8%;
		top: 10%;
		@media (max-width: 1588px){
			right: 3%;
		}
		@media (max-width: 1380px){
			display: none;
		}
    }
`;

export const CenteredVideoComponentWrapper = styled.div`
    position: relative;
    .bean-and-burst{
        display: block;
        margin: 0 auto 15px auto;
    }
    .container{
		display: flex;
		flex-flow: column;
        .text-content{
            text-align: center;
        }
        .heading-row{
            display: flex;
            flex-flow: column;
            text-align: center;
			width: 800px;
			margin: auto;
			max-width: 100%;
            gap: 20px;
            .rich-text{
				line-height: 1.5;
				font-size: 18px;
            }
        }
    }
	h2 {
		font-family: 'bordonaro', bordonaro;
		font-size: 56px;
		font-style: normal;
		font-weight: 400;
		line-height: 60px;
		text-transform: capitalize;
		color: ${({ theme }) => theme.colours.blue};
		margin-bottom: 0;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
		@media (max-width: 1024px) {
			font-size: 32px;
			line-height: 36px;
			text-transform: capitalize;
		}
	}

	p {
		width: 750px;
		max-width: 100%;
		margin: auto;
		text-align: center;
        font-size: 20px;
        display: block;
        color: ${({theme}) => theme.colours.brown};
        span{
            display: inline-block;
            &.bold{
                font-weight: 700;
				color: ${({theme}) => theme.colours.red};
            }
        }
	}
    .videoSubText{
		width: 750px;
		max-width: 100%;
		margin: auto;
		text-align: center;
		font-size: 24px;
		display: block;
        font-weight: 500;
		color: ${({theme}) => theme.colours.brown};
        margin-bottom: 15px;
		span{
			display: inline-block;
			color: ${({theme}) => theme.colours.red};
			&.bold{
				font-weight: 700;
				color: ${({theme}) => theme.colours.red};
			}
		}   
    }
    #sweepbtn{
            background-color: ${({ theme }) => theme.colours.blue};
			&:hover{
				background-color: ${({ theme }) => theme.colours.yellow};
                color: ${({ theme }) => theme.colours.blue};
				span:nth-child(2) {
					background-color: ${({ theme }) => theme.colours.blue};
				}
				span:nth-child(3) {
					svg{
						fill: ${({ theme }) => theme.colours.blue};
						path{
							stroke: ${({ theme }) => theme.colours.blue};
						}
					}
				}
			}
    }

    .disclosureText{
		margin: 20px auto 0 auto;
		text-align: center;
		font-size: 16px;
		line-height: 1.3;
        a{
            color: ${({theme}) => theme.colours.brown};
        }
    }
    .button-link{
		margin: 20px auto;
		display: block;
		align-self: center;
    }
	.video-holder {
		display: block;
		max-width: 100%;
		width: 1029px;
		margin: 40px auto 0 auto;
        position: relative;
	}
    .bottom-links{
        display: flex;
        justify-content: center;
        gap: 25px;
        margin-top: 15px;
        a{
			color: ${({theme}) => theme.colours.brown};
			text-decoration: underline;
			font-size: 20px;
            font-weight: 500;
            transition: all 0.4s ease-in-out;
            &:hover{
                color: ${({theme}) => theme.colours.red};
            }
        }
    }
	.video-div {
		background-color: transparent;
		position: relative;
		width: 100%;
		padding-top: 56.25%;
		border-radius: 0;
		background-size: 102%;
		background-position: center center;
        overflow: hidden;
        cursor: pointer;
		filter: drop-shadow(0px 4px 16px rgba(62, 25, 5, 0.48));
		.play-cut-out-button{
            width: 100%;
            height: 100%;
			transition: all 0.4s ease-in-out;
			path{
				transition: all 0.4s ease-in-out;
			}
		}
        &:hover{
			.play-cut-out-button{
                path{
					fill: ${({ theme }) => theme.colours.yellow};   
                }
			}
        }
		iframe {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
		.inner-content {
			height: 100%;
			width: 100%;
			display: flex;
			flex-flow: column;
			justify-content: center;
			align-items: end;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			justify-content: end;
			img {
				align-self: center;
				display: block;
				margin: auto;
			}
			svg {
				align-self: center;
				display: block;
				margin: auto;
			}
			span {
				font-family: 'Chunk';
				font-style: normal;
				font-weight: 900;
				font-size: 45px;
				line-height: 1;
				text-align: center;
				text-transform: uppercase;
				color: #FFFFFF;
				margin: 0 auto 50px auto;
				max-width: 100%;
                width: 380px;
			}
			.play-video-button {
				width: 100px;
				height: 100px;
				margin: auto;
				position: absolute;
				border: 0;
				background: none;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
                cursor: pointer;
                padding: 0;
                @media (max-width: 650px){
					width: 50px;
					height: 50px;
					min-width: 50px;
					min-height: 50px;
                }
			}
		}
		@media (max-width: 900px) {
			border-radius: 25px;
			.inner-content{
                span{
                    font-size: 28px;
                    max-width: 80%;
					margin-bottom: 5%;
                }
            }
		}
		@media (max-width: 650px) {
			.inner-content{
				span{
					font-size: 20px;
				}
			}
		}
	}
`;